import React, {useEffect} from "react";
import Contact from "../components/contact";
import HomeContent from "../components/homeContent";
import Layout from "../components/layout";
import Message from "../components/message";
import SEO from "../components/seo";

function IndexPage() {
  useEffect(() => {
    const { hash } = location
  
    if (!hash) 
      { window.requestAnimationFrame(() => {
        window.scroll({top: 0, left: 0})
      })  
      return}
  
    window.requestAnimationFrame(() => {
      const anchor = document.querySelector(hash)
      const offset = anchor.getBoundingClientRect().top + window.scrollY
  
      window.scroll({ top: offset, left: 0 })
    })
  }, [])
  return (
    <Layout>
      <SEO
        keywords={[`Z世代`, `ベンチャー`]}
        title="Z世代によるベンチャー企業 MRISです"
      />
      <section className="text-center">
        <HomeContent />
      </section>
      <p className="border-b-4 mt-16 mb-4 border-black-500 text-center" id="message">Message</p>
      <Message />
      <p className="border-b-4 mt-16 mb-4 border-black-500 text-center" id="contact">Contact</p>
      <Contact />
    </Layout>
  );
}

export default IndexPage;
