
import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import "./modal.css";

const BussinessDescription = (props) => {
    Modal.setAppElement("body");
    return (
        <Modal isOpen={props.isOpen} 
                   overlayClassName={{
                    base: "overlay-base",
                    afterOpen: "overlay-after",
                    beforeClose: "overlay-before"
                  }}
                  className={{
                    base: "content-base",
                    afterOpen: "content-after",
                    beforeClose: "content-before"
                  }}
                  
                  
                   closeTimeoutMS={500}
                   onRequestClose={props.toggleOpen} >
                       <div className="w-full bg-white h-full flex flex-col justify-center items-center rounded shadow px-4 py-4">
                           <h4 className="font-semibold text-lg text-gray-700 tracking-relaxed">{props.subTitle}</h4>
                           <p className="text-gray-500 my-1">{props.description}</p>
                           <div>
                               <button onClick={props.toggleOpen}
                                       className="bg-blue-500 text-white font-bold px-4 py-2 text-sm uppercase  rounded tracking-wider focus:outline-none hover:bg-blue-600">
                                   <a href="/#contact" >Contact</a>
                               </button>
                           </div>
                       </div>
                       <div className="absolute my-2 top-0 left-2">
                        <button onClick={props.toggleOpen}  className="text-gray-600 px-1 py-1 cursor-pointer opacity-80 md:opacity-50 hover:opacity-100">閉じる</button>
                       </div>
                       
                
            </Modal>
    );
};

BussinessDescription.propTypes = {
    subTitle: PropTypes.string,
    description : PropTypes.string,
    isOpen : PropTypes.bool,
    toggleOpen: PropTypes.func
}

export default BussinessDescription;