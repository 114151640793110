import { StaticImage } from "gatsby-plugin-image";
import React from "react";

const Message = () => {
    return(
        <section className="flex flex-col items-center md:flex-row " >
        <div className="md:w-2/3 md:mr-8">
          <div className="pl-4  font-serif leading-relaxed text-justify border-l-4  border-gray-700">
            <p>昨今、様々なものが”アナログ”から”デジタル”へと移り変わっています。
            その結果、人々が生活していく上でスマートフォンなどのデジタル機器が
            必需品になってきています。</p>
            <p>我々MRISのメンバーは物心ついた時からインターネットが普及している
            Z世代の人間です。
            このような環境で生きてきた我々が新しい時代のコンテンツを創ることに
            意味があると思い会社を立ち上げました。</p>
            <p>しかし、MRISは”デジタル”なモノのみが便利であるとは考えておりません。
            人と人の対面での直接的なコミュニケーションは何よりも大切であると思い、
            ”デジタルとアナログが共存したコンテンツ創り”をしていきます。</p>
          </div>

          <p className="block mt-4 text-xs font-bold text-right">
            株式会社MRIS一同
          </p>
        </div>

        <figure className="w-2/3 md:w-1/3">
          <StaticImage src="../images/mrisRabit.jpg" placeholder="dominantColor" alt="Logo"/>
        </figure>
      </section>
    )
};

export default Message;