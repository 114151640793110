
import React, {  } from "react";
import {navigate} from "gatsby";
import { useForm} from "react-hook-form";
const errorMessageUtilities = "block mb-2 text-xs font-bold text-red-400 "

const Contact = () => {
  const {  register, handleSubmit, formState: { errors }, watch,reset } = useForm();
  const encode = (data) => {
    return Object.keys(data)
      .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
  };


  const onSubmit = (data, event) => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...data })
    }).then(() => reset())
      .then(() => navigate("/"))
      .then(() => alert("お問い合わせを受け付けました"))
      .catch(() => alert("お問い合わせに失敗しました"));
      event.preventDefault();
  };

  const watchIsCompany = watch("belongs", false)

  return(
        <section className="">
           <p className="mb-8 leading-loose">
           送信後担当者が連絡いたします。各種SNSのDMからもお問い合わせを受け付けております。
          </p>
        <form onSubmit={handleSubmit(onSubmit)}
              name="contact"
              method="post"
              data-netlify="true" 
              data-netlify-honeypot="bot-field"
              >
          <input type="hidden" name="form-name" value="contact" />
          <input type="hidden" name="bot-field" />

          <label
            className="block mb-2 text-xs font-bold uppercase"
            htmlFor="email"
          >
            メールアドレス
          </label>

          <input
            className="w-full md:w-1/2 mb-6 rounded-md border-gray-800"
            name="email"
            placeholder="sample@sample.com"
            type="email"
            {...register("email", {required: true})}
          />
          {errors.email && <p className={`${errorMessageUtilities}`}>メールアドレスは必須です</p>}

          <label
            className="block mb-2 text-xs font-bold uppercase"
            htmlFor="所属"
          >所属</label>
          
          <select className="w-full md:w-1/2 mb-6 rounded-md border-gray-800" name="belongs" {...register("belongs")} defaultValue="所属をお選びください"
            >
              <option>
                所属をお選びください
              </option>
              <option value="法人様">
                法人様
              </option>
              <option value="個人様"> 
                個人様
              </option>
            </select>
            {watchIsCompany==="法人様" && <p className={`${errorMessageUtilities}`}>法人様は会社名をご記入ください</p>}
            {watchIsCompany==="法人様" && <input className="w-full md:w-1/2 mb-6 rounded-md border-gray-800"
                                          name="company"
                                          placeholder="例）株式会社〇〇"
                                          type="text"
                                          {...register("company")}
                                          ></input> }
          <label
            className="block mb-2 text-xs font-bold"
            htmlFor="name"
          >
          お名前
          </label>
          <input
            className="w-full md:w-1/2 mb-6 rounded-md border-gray-800"
            name="name"
            placeholder="MRIS太郎"
            type="text"
            {...register("name", {required: true})}
          />
          {errors.name && <p className={`${errorMessageUtilities}`}>お名前は必須です</p>}

          <label
            className="block mb-2 text-xs font-bold"
            htmlFor="message"
          >
            お問い合わせ内容
          </label>

          <textarea
            className="w-full mb-6 rounded-md border-gray-800"
            id="message"
            name="message"
            placeholder="お問い合わせ内容をご記入ください。"
            rows="8"
            {...register("message", {required: true, minLength: 15})}
          />
          {errors.message?.type === "required" && <p className={`${errorMessageUtilities}`}>お問い合わせ内容をご記入ください。</p>}
          {errors.message?.type === "minLength" && <p className={`${errorMessageUtilities}`}>お問い合わせ内容は15文字以上ご記入ください。</p>}


          <button type="submit" 
            className="px-4 py-2 text-sm font-bold text-white bg-gray-700 border-b-4 border-gray-800 rounded hover:border-gray-700 hover:bg-gray-600">
            送信
          </button>
        </form>
      </section>
    );
}

export default Contact;