
import React, { useState } from 'react';
import BussinessDescription from './Modal/bussiness';
import * as INFO from "./INFO/featureInfo";

const utilityGridContent = "min-h-1/4 md:row-span-3 border-2 rounded-md transform  transition delay-150 duration-300 ease-out flex flex-col md:flex-row items-center justify-center hover:bg-white hover:skew-y-3 cursor-pointer";
const utilityForTitles = "font-bold font-sans text-lg md:text-4xl";
const utilityForDescription = "text-xs md:text-base font-light md:font-medium px-1 md:px-2"

                   


const HomeContent = () => {
    
    const [isShowCreate, setIsShowCreate] = useState(false);
    const openCreate = () => {
        setIsShowCreate((prev) => !prev);
    }
    const [isShowSpread, setIsShowSpread] = useState(false);
    const openSpread = () => {
        setIsShowSpread((prev) => !prev);
    }
    const [isShowProgress, setIsShowProgress] = useState(false);
    const openProgress = () => {
        setIsShowProgress((prev) => !prev);
    }
    
    return (
        <div className="relative"> 
        <BussinessDescription isOpen={isShowCreate} 
            toggleOpen={openCreate} 
            subTitle={INFO.CREATEINFO.subtitle}
            description={INFO.CREATEINFO.description}/>

        <BussinessDescription isOpen={isShowSpread} 
            toggleOpen={openSpread} 
            subTitle={INFO.SPREADINFO.subtitle}
            description={INFO.SPREADINFO.description}/>

        <BussinessDescription isOpen={isShowProgress} 
            toggleOpen={openProgress} 
            subTitle={INFO.PROGRESSINFO.subtitle}
            description={INFO.PROGRESSINFO.description}/>
        <p className="border-b-4 border-black-500 m-3 text-2xl md:text-4xl text-gray-600" id="our-feature">Our Feature</p>
        
        <div className="gap-x-4 gap-y-2 md:space-y-0  md:gap-x-8 md:gap-y-3 grid grid-cols-2 md:grid-row-7 md:grid-flow-col h-64 md:h-screen" >
            <div className={`bg-blue-200 ${utilityGridContent}`} onClick={openCreate}>
                <p className={`${utilityForTitles} text-gray-600 `}>Create</p>
                <p className={`${utilityForDescription}`}>コンテンツを生み出すプロセスです</p>
            </div>
            <div className={`bg-blue-400 ${utilityGridContent}`} onClick={openSpread}>
                <p className={`${utilityForTitles} text-gray-700`}>Spread</p>
                <p className={`${utilityForDescription}`}>コンテンツを広めましょう</p>
            </div>
            <div className={`md:row-start-2 bg-blue-600 ${utilityGridContent}`} onClick={openProgress}>
                <p className={`${utilityForTitles} text-gray-300`}>Progress</p>
                <p className={`${utilityForDescription}`}>終わりはありません次を考えます</p>
            </div>
            <a className={`md:row-start-5 bg-gray-400 ${utilityGridContent} hover:-skew-y-3`} href="/#contact">
                
                <p className={utilityForTitles}>Contact</p>
                <p className={`${utilityForDescription}`}>まずはお問い合わせください</p>
                
                
            </a>
        </div>
        </div>
    )

}

export default HomeContent;